import { Col, Row } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Content = styled.div`
  max-width: 470px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin-top: 16px;
`;

export const Label = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 1.5;
  text-align: center;
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
`;

export const Footer = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
`;

export const FooterLabel = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
`;

export const BrowserWrapper = styled(Row)`
  width: 300px;
`;

export const Browser = styled(Col)`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
`;

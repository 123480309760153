// import { Row } from 'antd';
import styled from 'styled-components';
import CustomModal from '../../Components/UI/CustomModal';

export const ForexPairsRow = styled.div`
  align-items: center;
  display: flex;
  margin: auto;
  width: 100%;
  white-space: nowrap;
  padding-top: 10px;
  gap: 15px;
  height: 60px;
  overflow-y: scroll;
  overflow-x: scroll;

  @media screen and (max-width: 480px) {
    height: auto;
  }

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .forexPair {
    text-align: left;
    font-weight: 600;
    line-height: 21px;
    display: flex;
    /* min-width: 130px; */
    font-size: 0.875rem;
    height: 3.125rem;
    line-height: 1.5;
    padding-left: 10px;
    align-items: center;

    .anticon-close {
      svg {
        path {
          fill: var(--color-text-reverse) !important;
        }
      }
    }

    @media screen and (max-width: 480px) {
      height: 35px;
    }

    .text-content {
      .divider {
        display: none;

        @media screen and (max-width: 480px) {
          display: block;
          padding: 0px 5px;
        }
      }

      @media screen and (max-width: 480px) {
        display: inline-flex;
      }
    }

    .ant-btn-icon {
      margin-inline-end: 0px !important;
    }
  }

  .cancel__icon {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: var(--color-text);
    border-radius: 50%;
    height: 20px;
    width: 20px;
    color: var(--color-black);
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const FlagWrapper = styled.div`
  position: relative;
  margin-right: 10px;

  img {
    width: 25px;
    height: 25px;
  }

  /* img:last-child {
    position: absolute;
    top: 8px;
    right: -8px;
  } */
`;

export const ForexPairContainer = styled.div`
  display: flex;
  align-items: center;

  .ant-btn-primary.create_forex {
    padding: 5px 16px;
    margin-right: 15px;
    margin-bottom: -10px;
    height: 3.125rem;

    @media screen and (max-width: 480px) {
      height: 35px;
    }
  }
`;

export const ForexModalWrapper = styled(CustomModal)`
  .ant-list-header {
    color: var(--color-text-secondary);
    border-block-end-width: 2px;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .flags__container {
    display: flex;
    align-items: center;
    position: relative;
    /* margin-right: 25px; */

    img {
      width: 25px;
      height: 25px;
      margin-right: 15px;
    }
  }

  .ant-list-item {
    flex-wrap: wrap;
    cursor: pointer;

    &:hover {
      background-color: var(--color-grey-6);
    }

    .pair__info {
      display: flex;
    }
  }

  .ant-list-item {
    .ant-col:first-child {
      @media screen and (max-width: 768px) {
        margin-bottom: 20px;
      }
    }
  }

  .pair__header {
    color: var(--color-text-secondary);
    margin-bottom: 5px;
    display: none;

    @media screen and (max-width: 768px) {
      display: block;
    }
  }

  .diff__percentage {
    color: var(--color-warning-dark);
    text-align: center;

    @media screen and (max-width: 768px) {
      text-align: left;
    }
  }
`;

export const TerminalContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;

  .chart-container {
    width: calc(100% - 15.5rem);
    position: relative;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    .right-arrow-icon {
      position: absolute;
      right: 60px;
      bottom: 40px;

      svg {
        fill: var(--color-text);
      }
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ChartNotAvailable = styled.div`
  display: flex;
  padding: 40px 20px;
  width: 400px;
  background-color: var(--color-grey-3);
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h4 {
    text-align: center;
    font-weight: 600px;
  }
  p {
    text-align: center;
    margin-top: 30px;
    font-size: 14px;
  }
  .graphImg {
    padding: 30px;
    background-color: var(--color-grey-1);
    border-radius: 50%;
    margin-bottom: 20px;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
  }
`;

/* eslint-disable no-console */
const SegmentTag = {
  name: 'SegmentTag',
  totalStep: 2,
  needDefaultPointFigure: true,
  needDefaultXAxisFigure: true,
  needDefaultYAxisFigure: true,
  zLevel: 1,
  createPointFigures: ({ overlay, coordinates, bounding, ...rest }) => {
    let { label, activeTradeIndex, type } = overlay.extendData ?? '';

    const startX = coordinates[0].x - (140 * activeTradeIndex + 20);
    const startY = coordinates[0].y;

    const startX1 = coordinates[0].x;
    const startY1 = coordinates[0].y;

    return [
      {
        type: 'line',
        attrs: {
          coordinates: [
            {
              x: startX,
              y: startY,
            },
            {
              x: startX1,
              y: startY1,
            },
          ],
        },
        styles: {
          color: type === 'Long' ? 'green' : 'red',
          borderSize: 3,
        },
        ignoreEvent: true,
      },
      {
        type: 'custom-circle',
        attrs: {
          x: startX1,
          y: startY1,
        },
        styles: {
          style: 'stroke-fill',
          borderColor: type === 'Long' ? 'green' : 'red',
        },
        ignoreEvent: true,
      },
      {
        type: 'custom-text',
        attrs: {
          x: startX,
          y: startY + 10,
          text: label ?? '',
          align: 'right',
          baseline: 'bottom',
        },
        styles: {
          borderRadius: 10,
          paddingLeft: 30,
          type: type,
        },
        ignoreEvent: true,
      },
    ];
  },
};

export default SegmentTag;

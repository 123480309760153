import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import globe from '../../../assets/icons/globe.svg';
import cancel from '../../../assets/icons/cancel.svg';
import drop from '../../../assets/icons/dropdownIcon.svg';
import sideArrow from '../../../assets/images/sideArrow.svg';
import NavigationLink from '../../UI/NavigationLink/NavigationLink';
import routes from '../../../utils/routes';
import { DefaultNavbarContainer, DropdownContainer, NavbarMobileMenu } from './style.js';
import { useTranslation } from 'react-i18next';
import { useUserStore } from '../../../store/useUserStore.js';
import LogoWhite from '../../../assets/icons/logo-white font.svg';
import LogoBlack from '../../../assets/icons/logo-black font.svg';
import useMediaQuery from '../../../hooks/useMediaQuery.js';
import LanguageSelect from '../../LanguageSelect/LanguageSelect.jsx';

const PublicNavbar = () => {
  const { t } = useTranslation();
  const { setLanguage: setLanguageInStore, language: storeLanguage } = useUserStore();
  const [showOptions, setShowOptions] = useState(false);
  const { theme } = useUserStore();
  const isMobile = useMediaQuery(468);

  return (
    <DefaultNavbarContainer theme={theme}>
      <div className="container">
        <div className="first-section">
          <NavigationLink customClass={'logo-link'} to="/">
            {!isMobile && <img src={theme === 'light' ? LogoBlack : LogoWhite} alt="logo" />}
          </NavigationLink>
          <nav>
            <NavigationLink to={routes.FAQ}>{t('FAQ')}</NavigationLink>
            <NavigationLink to={routes.ABOUT_US}>{t('About us')}</NavigationLink>
          </nav>
        </div>
        <div className="second-section">
          <nav>
            <div className="globe">
              <img src={globe} alt="change-language" />
              <LanguageSelect
                onChange={(v) => {
                  setLanguageInStore(v);
                }}
                value={storeLanguage}
              />
            </div>
          </nav>
          <NavigationLink customClass="header__button login" to={routes.LOGIN}>
            {t('Login')}
          </NavigationLink>
          <NavigationLink customClass="header__button signup" to={routes.REGISTRATION}>
            {t('Sign up')}
          </NavigationLink>
          <NavbarMobileMenu onClick={() => setShowOptions(!showOptions)}>
            <img src={showOptions ? drop : cancel} alt="change-language" />{' '}
          </NavbarMobileMenu>
        </div>
      </div>
      {showOptions ? (
        <DropdownContainer>
          <Link to={routes.FAQ} className="option">
            <p>{t('FAQ')}</p>
            <img src={sideArrow} alt="change-language" />
          </Link>
          <Link to={routes.ABOUT_US} className="option">
            <p>{t('About us')}</p>
            <img src={sideArrow} alt="change-language" />
          </Link>
          <div className="option lastChild">
            <LanguageSelect onChange={(v) => setLanguageInStore(v)} value={storeLanguage} />
          </div>
        </DropdownContainer>
      ) : null}
    </DefaultNavbarContainer>
  );
};

export default PublicNavbar;

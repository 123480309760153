import React, { useEffect, useState } from 'react';
import CustomModal from '../../../Components/UI/CustomModal';
import { useNavigate } from 'react-router-dom';
import routes from '../../../utils/routes';
import { Button } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import CustomSelect from '../../../Components/UI/CustomSelect/CustomSelect';
import { addSupportSchema } from '../schema';
import CustomTextarea from '../../../Components/UI/CustomTextarea/CustomTextarea';
import { CustomForm } from '../../../styles/style';
import { useAddRequest, useReplyTicket } from '../../../api/supportApi';
import CustomUpload from '../../../Components/UI/CustomUpload/CustomUpload';
import { useTranslation } from 'react-i18next';

const AddRequestPage = ({ isReply = false, isDefaultOpen = true, requestId, onChange = () => {} }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [files, setFiles] = useState([]);
  const addRequest = useAddRequest();
  const replyRequestTicket = useReplyTicket();

  const [isOpen, setIsOpen] = useState(isDefaultOpen);

  const subjects = [
    {
      label: t('Verification'),
      value: 'Verification',
    },
    {
      label: t('Deposit') + ' / ' + t('Withdrawal'),
      value: 'Deposit/Withdrawal',
    },
    {
      label: t('Other issue'),
      value: 'Other Issue',
    },
  ];

  const onSubmit = async (data) => {
    const payload = { ...data, files: files };

    if (isReply) {
      payload.ticketId = requestId;
      await replyRequestTicket.mutateAsync(payload);
      reset();
    } else {
      await addRequest.mutateAsync(payload);
    }

    setIsOpen(false);
    onChange(false);
  };

  const onCancel = () => {
    if (!isReply) {
      navigate(routes.SUPPORT.HOME + routes.SUPPORT.MY_REQUEST);
    }
    reset();
    setIsOpen(false);
    onChange(false);
  };

  useEffect(() => {
    setIsOpen(isDefaultOpen);
  }, [isDefaultOpen]);

  return (
    <div>
      <CustomModal
        title={isReply ? `${t('Drop Message')} | #${requestId}` : t('Add Request')}
        open={isOpen}
        onOk={onSubmit}
        onCancel={onCancel}
        width={900}
        footer={[
          <Button
            type="primary"
            onClick={handleSubmit(onSubmit)}
            loading={addRequest.isPending || replyRequestTicket.isPending}
          >
            {t('Submit')}
          </Button>,
        ]}
      >
        <CustomForm>
          {!isReply && (
            <Controller
              control={control}
              name="type"
              rules={addSupportSchema(t).type}
              render={({ field: { onChange, value } }) => (
                <CustomSelect
                  label={t('Select Subject')}
                  placeholder={t('Select your subject')}
                  value={value}
                  status={errors?.type ? 'error' : ''}
                  message={errors?.type?.message}
                  optionFilterProp="children"
                  onChange={onChange}
                  options={subjects}
                  style={{ maxWidth: 360 }}
                />
              )}
            />
          )}
          <Controller
            control={control}
            name="message"
            rules={addSupportSchema(t).message}
            render={({ field: { onChange, value } }) => (
              <CustomTextarea
                value={value}
                onChange={onChange}
                status={errors?.message ? 'error' : ''}
                message={errors?.message?.message}
                label={t('Message')}
                placeholder={t('Your Message')}
                maxLength={250}
                showCount
              />
            )}
          />
          <CustomUpload onChange={({ fileList }) => setFiles(fileList)} />
        </CustomForm>
      </CustomModal>
    </div>
  );
};

export default AddRequestPage;

export const hashRoutes = {
  SETTINGS: '#app-setting',
  LEADER_BOARD: '#leader-board',
};

const routes = {
  NOT_SUPPORTED: '/not-supported',
  HOME: '/',
  NATIVE_CHART: '/native-chart',
  DEMO_TRADE: '/trade',
  ABOUT_US: '/about-us',
  CONTACT_US: '/contact-us',
  FAQ: '/faq',
  LOGIN: '/login',
  REGISTRATION: '/registration',
  FORGOT: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  NOT_FOUND: '*',
  TRADE_HISTORY: '/trade-history',
  VERIFY_EMAIL: '/verify-email/:id/:code',
  OTP_VERIFY: '/otp-verify',
  WITHDRAW: '/withdraw',
  TERMINAL: { ROOT: '/terminal/*', HOME: '/terminal' },
  PROFILE: {
    ROOT: '/profile/*',
    HOME: '/profile/',
    ACCOUNT: 'account',
    DEPOSIT: 'deposit',
    TRANSFER: 'deposit/:transferId',
    TRANSFER_HOME: 'transfer/',
    PERFECT_MONEY: 'perfect-money',
    MASTER_CARD: 'master-card',
    UPI_CARD: 'upi-card',
    TRANSACTION: 'transaction',
    WITHDRAWAL: 'withdrawal',
    TRADES: 'trades',
  },
  SUPPORT: {
    ROOT: '/support/*',
    HOME: '/support/',
    MY_REQUEST: 'my-request',
    FAQ: 'faq',
    ADD_REQUEST: 'add-request',
    SUPPORT_DETAIL: '/support/:requestId',
  },
  UNDER_MAINTENANCE: '/under-maintenance',
  LAUNCHING_SOON: '/launching-soon',
};

export default routes;
